<template>
	<edit-template @confirm="submit" @cancel="cancel" :cancelRemind="false" confirmText="提交" cancelText="取消">
		
    <div class="form-body" style="padding: 35rem 0 0 60rem">
      <h4>基础信息</h4>
      <br>
      <el-form label-width="100rem" ref="form" class="demo-ruleForm" :model="formData" :rules="rules">
        <el-form-item label="设置年月" prop="month">
          <p>{{ formData.month }}</p>
        </el-form-item>
        <el-form-item label="校区" prop="grade_info">
          <p>{{ formData.school_info }}</p>
        </el-form-item>
        <el-form-item label="年级" prop="grade_info">
          <p>{{ formData.grade_info }}</p>
        </el-form-item>
        <el-form-item label="科目" prop="subject_info">
          <p>{{ formData.subject_info }}</p>
        </el-form-item>
        <br>
        <h4>学习习惯完成率</h4>
        <br>
        <el-form-item v-for="item in knackConfig" :label="item.name" :prop="item.key">
          <el-input-number v-model="formData[item.key]" :precision="0" :min="0" :max="100" @blur="inputNumberBlur($event,item.key)" class="inputNumber"></el-input-number>
          %
        </el-form-item>
      </el-form>
    </div>
    
  
</edit-template>
</template>

<script>
export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  data() {
    return {
      // 获取处理类型
      knackConfig: [],
      knackList: [],
      // 表单数据
      formData: {},
      // 表单验证规则
      rules: {}
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$_axios2('/api/educational-routine/routine-detail?id=' + this.$route.query.id, {logic: 1}).then(res => {
        this.formData = res.data
        this.knackList = res.data.knack_list
        this.getKnackConfig()
      })
    },
    getKnackConfig() {
      this.$_axios2.get('api/educational-routine/knack-list').then(res => {
        if (res.data.status == 0) {
          this.knackConfig = res.data.data
          this.knackConfig.forEach(item => {
            let val = 100;
            this.knackList.forEach(kanck => {
              if (item.key == kanck.key) {
                val = kanck.value;
              }
            })
            this.$set(this.formData, item.key, val)
          })
        }
      })
    },

    // 表单提交
    submit() {
      if (!this.formData[this.knackConfig[0].key] && !this.formData[this.knackConfig[1].key] && !this.formData[this.knackConfig[2].key] && !this.formData[this.knackConfig[3].key]) {
        this.$message.error({ message: '请至少设置一项学习习惯完成率', showClose: true })
        return;
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$_axios2.post('api/educational-routine/update-routine', {
            ...this.formData,
            id: this.$route.query.id
          }).then(res => {
            if (res.data.status === 0) {
              this.$message.success({
                message: '编辑成功',
                onClose: () => {
                  this.$store.commit("setPage",1);
                  this.$router.go(-1)
                }
              })
            }
          })
        }
      })
    },
    // 取消按钮
    cancel() {
      this.$confirm('是否取消编辑?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.back()
      })
    },

    inputNumberBlur(e,key){
      if(e.target.ariaValueNow === 'undefined'){
        this.formData[key] = 0
      }
    }
  },
}
</script>

<style scoped>
.inputNumber {
  width: 15.06944444444444vw !important;
}
</style>
